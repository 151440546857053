// ~~~ CONTENTFUL PAGE ~~~
/* eslint-disable */

import React from 'react'
import Helmet from 'react-helmet'
import Layout from 'components/Layout'
import Hero from 'components/Contentful/Hero'
import ImageAndWordsCollection from 'components/Contentful/ImageAndWordsCollection'
import ImageAndWords from 'components/Contentful/ImageAndWords'

const Page = props => {
  return (
    <Layout location={props.location}>
      <Helmet
        title=""
        meta={[
          { name: 'description', content: '' },
          { name: 'keywords', content: '' },
        ]}
      >
        
      </Helmet>
      <Helmet meta={[{ name: 'robots', content: 'noindex, nofollow' }]}></Helmet>
      <div
          className='bg__flex--white'
          key='QIIHjWjLF0axQAHOLyWly-3GA3J9rpLhjdFwqU0dWeKo'
          id='haben-sie-eine-stoerung-an-einer-von-uns-installierten-waermepumpe'
        >
          <Hero
            {...{
    "type": "hero",
    "id": "3GA3J9rpLhjdFwqU0dWeKo",
    "updatedAt": "2021-04-12T14:11:19.770Z",
    "title": "Haben Sie eine Störung oder einen Notfall an einer von uns installierten Wärmepumpe?",
    "size": "Groß",
    "teaser": "Wir helfen Ihnen gerne weiter! Oft kann der Fehler mit ein paar wenigen Handgriffen behoben werden. Vielleicht hilft die Störungsanleitung bereits weiter. Wenn nicht, dann bestimmt die Kundenservice-Hotline des Herstellers.",
    "image": {
        "description": "Einfamilienhaus im Naturstein-/Betonstil in Waldumgebung",
        "contentType": "image/png",
        "url": "//images.ctfassets.net/wbm1kpreb3m8/59HERrI2LmquS6WsEywMce/e7196bf9d27afacc79704bfff37e4ec0/flo-pappert-201009.png"
    },
    "anchorName": "haben-sie-eine-stoerung-an-einer-von-uns-installierten-waermepumpe"
}} location={props.location}
          />
        </div>
<div
          className='bg__flex--grey'
          key='QIIHjWjLF0axQAHOLyWly-57YOuu0UQit8hoeSPgQczU'
          id=''
        >
          <ImageAndWordsCollection
            {...{
    "type": "imageAndWordsCollection",
    "id": "57YOuu0UQit8hoeSPgQczU",
    "updatedAt": "2023-10-12T15:25:48.721Z",
    "title": "Unsere Hersteller greifen Ihnen gerne unter die Arme!",
    "elements": [
        {
            "type": "imageAndWords",
            "id": "AJS6IFNlM8F6ZU5PwXlKi",
            "updatedAt": "2023-10-12T15:26:40.163Z",
            "title": "alpha innotec",
            "text": "<p><strong>Kontaktdaten:</strong></p>\n<p>alpha innotec\nc/o ait Schweiz AG\nService\nIndustriepark\n6246 Altishofen</p>\n<p><strong>Kundenservice-Hotline:</strong>\n0848 80 40 80</p>\n<p><a href=\"https://www.alpha-innotec.ch/alpha-innotec/service/stoerungsmeldung.html\">Webseite Störungen/Notfälle</a></p>\n",
            "image": {
                "description": "Logo Alpha Innotec Wärmepumpen",
                "contentType": "image/svg+xml",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/1MKYoPax52UCqcueUuS4ou/f7a0a47d5c1f00c3d911f62d3fddc424/ait_Logo-01.svg"
            },
            "anchorName": "alpha-innotec"
        },
        {
            "type": "imageAndWords",
            "id": "78IowtgFYP3P6ax9B74p70",
            "updatedAt": "2023-10-12T15:24:35.982Z",
            "title": "Buderus",
            "text": "<p><strong>Kontaktdaten:</strong></p>\n<p>Buderus\nBosch Thermotechnik AG\nServicecenter Deutschschwei\nSennweidstrasse 43\n6312 Steinhausen</p>\n<p><strong>Kundenservice-Hotline:</strong>\n0844 111 666</p>\n<p><a href=\"https://www.buderus.com/ch/de/service/service-kontakt/\" title=\"Kontaktseite Buderus Service\">Webseite Störungen/Notfälle</a></p>\n",
            "image": {
                "description": "Buderus Bosch Logo",
                "contentType": "image/svg+xml",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/OkmKl0HJDim3zSIlGqcHI/ce9d6a405f2f5b52e68077a8341df445/Buderus_Bosch_SVG.svg"
            },
            "anchorName": "buderus"
        },
        {
            "type": "imageAndWords",
            "id": "4lqpbbPF87eco8cdpRumkk",
            "updatedAt": "2023-10-12T15:26:49.824Z",
            "title": "NIBE",
            "text": "<p><strong>Kontaktdaten:</strong></p>\n<p>NIBE Wärmetechnik\nc/o ait Schweiz AG\nIndustriepark\n6246 Altishofen</p>\n<p><strong>Kundenservice-Hotline:</strong>\n0848 88 33 99</p>\n<p><a href=\"https://www.nibe.eu/ch/de/support\" title=\"Webseite für Störungsmeldungen\">Webseite Störungen/Notfälle</a></p>\n<p><a href=\"https://go.heizungsmacher.ch/l/503181/2021-04-09/2c2xctj/503181/16179510608zHQHTSS/St__rungsanleitung_Nibe_S_Serie.pdf\" title=\"Störungsanleitung NIBE S-Serie\">Störungsanleitung S-Serie</a>\n<a href=\"https://go.heizungsmacher.ch/l/503181/2021-04-09/2c2xctg/503181/1617951018IWuyB32E/St__rungsanleitung_Nibe_SMO.pdf\">Störungsanleitung SMO</a></p>\n<p>Fehlermeldungen</p>\n<ul>\n<li><a href=\"https://www.nibe.eu/ch/de/support/fehlermeldung-s-serie\">S-Serie</a></li>\n<li><a href=\"https://www.nibe.eu/ch/de/support/fehlermeldung-f-serie\">F-Serie</a></li>\n</ul>\n",
            "image": {
                "description": "Logo NIBE Wärmepumpen",
                "contentType": "image/svg+xml",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/1kYauulSew7223PSLVoaSY/bfd4374415edb5976a88a83a4d438796/NIBE_Logo-01.svg"
            },
            "anchorName": "nibe"
        },
        {
            "type": "imageAndWords",
            "id": "4azn0fOxqLro4Pv8aZwPfE",
            "updatedAt": "2023-10-12T15:26:58.991Z",
            "title": "STIEBEL ELTRON",
            "text": "<p><strong>Kontaktdaten:</strong></p>\n<p>STIEBEL ELTRON AG\nIndustrie West\nGass 8\n5242 Lupfig</p>\n<p><strong>Kundenservice-Hotline:</strong>\n0844 333 444</p>\n<p><a href=\"https://www.stiebel-eltron.ch/de/home/service/dienstleistungen/hilfe.html\" title=\"Webseite für Störungsmeldungen\">Webseite Störungen/Notfälle</a></p>\n<p><a href=\"https://go.heizungsmacher.ch/l/503181/2021-04-09/2c2xctl/503181/1617951107JWre5F0g/St__rungsanleitung_StiebelEltron.pdf\" title=\"Störungsanleitung Stiebel Eltron\">Störungsanleitung Stiebel Eltron</a></p>\n<p>Fehlermeldungen</p>\n<ul>\n<li><a href=\"https://www.stiebel-eltron.ch/de/home/produkte-loesungen/erneuerbare_energien/regelung_energiemanagement/wpmsystem/wpm/downloads.html\">WPM Notification List</a> --> auf der Website von Stiebel Eltron anklicken</li>\n</ul>\n",
            "image": {
                "description": "Logo Stiebel Eltron Wärmepumpen",
                "contentType": "image/svg+xml",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/1yTD2XeRRS20CyiKo2oAQS/a306b364720ee15fcc1a41c0bb5e34f8/Stiebel_Eltron_Logo-01.svg"
            },
            "anchorName": "stiebel-eltron"
        },
        {
            "type": "imageAndWords",
            "id": "5TnCLyKy59nomvEBJshgFo",
            "updatedAt": "2023-10-12T15:27:08.099Z",
            "title": "Viessmann",
            "text": "<p><strong>Kontaktdaten:</strong></p>\n<p>Viessmann (Schweiz) AG\nHauptsitz Spreitenbach\nIndustriestrasse 124\n8957 Spreitenbach</p>\n<p><strong>Kundenservice-Hotline:</strong>\n0848 88 88 90</p>\n<p><a href=\"https://www.viessmann.ch/de/wohngebaeude/ratgeber/heizungsstoerung.html\" title=\"Webseite für Störungsmeldungen\">Webseite Störungen/Notfälle</a></p>\n<p><a href=\"https://go.heizungsmacher.ch/l/503181/2021-04-09/2c2xctn/503181/1617951148LMbtaaYo/St__rungsanleitung_Viessmann.pdf\" title=\"Störungsanleitung Viessmann\">Störungsanleitung Viessmann</a></p>\n",
            "image": {
                "description": "Logo Viessmann Wärmepumpen",
                "contentType": "image/svg+xml",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/3iUiUcSpCU6smMegWAeecC/74d9b69f17778c3d4b2095df7f0824c1/Viessmann_Logo-01.svg"
            },
            "anchorName": "viessmann"
        },
        {
            "type": "imageAndWords",
            "id": "3t5Q2LlsulaAZI5Pqhilf6",
            "updatedAt": "2023-10-12T15:27:16.726Z",
            "title": "CTC",
            "text": "<p><strong>Kontaktdaten:</strong></p>\n<p>CTC AG\nFurtbachstrasse 16/18\n8107 Buchs</p>\n<p><strong>Kundenservice-Hotline:</strong>\n0848 838 838</p>\n<p><a href=\"https://www.ctc-giersch.ch/servicekundendienst/dienstleistungen/stoerungsbehebung.html\" title=\"Webseite für Störungsmeldungen\">Webseite Störungen/Notfälle</a></p>\n",
            "image": {
                "contentType": "image/svg+xml",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/6gpSfC6i7HgizzMpgItpI9/6a69d3445050a64cb6bc06d2b99fe185/CTC_Logo-01.svg"
            },
            "anchorName": "ctc"
        },
        {
            "type": "imageAndWords",
            "id": "5eDIlyPApf3PbimgsomgqJ",
            "updatedAt": "2023-10-12T15:27:25.153Z",
            "title": "Ochsner Wärmepumpen",
            "text": "<p><strong>Kontaktdaten:</strong></p>\n<p>OCHSNER Wärmepumpen GmbH\nUraniastrasse 18\n8001 Zürich</p>\n<p><strong>Kundenservice-Hotline:</strong>\n0800 100 911</p>\n<p><a href=\"https://www.ochsner.com/de-ch/kundendienst/schweiz/\">Webseite Störungen/Notfälle</a></p>\n",
            "image": {
                "contentType": "image/svg+xml",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/1kwPPvsLm54dOyFT55HOlv/4c233c85c3ac15b808893d0221c691c3/Ochsner_Logo.svg"
            },
            "anchorName": "ochsner-waermepumpen"
        },
        {
            "type": "imageAndWords",
            "id": "2A96noeIGoznYQbqW3MmyV",
            "updatedAt": "2023-10-12T15:27:36.653Z",
            "title": "Hoval",
            "text": "<p><strong>Kontaktdaten:</strong></p>\n<p>Hoval\nRegion Nordwest\nLischmatt 7\n4624 Härkingen</p>\n<p><strong>Kundenservice-Hotline:</strong>\n0848 848 464</p>\n<p><a href=\"https://www.hoval.ch/de_CH/Service-Hotline-0848-848-464-/hovalhotline\">Webseite Störungen/Notfälle</a></p>\n",
            "image": {
                "contentType": "image/svg+xml",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/61U3k6snLdTHm1f5jY5WEa/b3367587ac7adaaa640faa007e6518fb/Hoval_Logo-01.svg"
            },
            "anchorName": "hoval"
        }
    ]
}} location={props.location}
          />
        </div>
<div
          className='bg__flex--white'
          key='QIIHjWjLF0axQAHOLyWly-1cRsbyndjZMxGvbTCM5Pyn'
          id='monitoring-empfohlen'
        >
          <ImageAndWords
            {...{
    "type": "imageAndWords",
    "id": "1cRsbyndjZMxGvbTCM5Pyn",
    "updatedAt": "2021-05-19T07:30:53.902Z",
    "title": "Aktives Monitoring",
    "text": "<p><strong>Eine richtig eingestellte Wärmepumpe ist effizient und bringt den richtigen Komfort</strong>. Wir überwachen Ihre Wärmepumpe aktiv und können Serviceeinsätze frühzeitig koordinieren. Einstellungen nehmen wir für Sie vor - so läuft das. Die Lebensdauer einer Wärmepumpe hängt primär von zwei Faktoren ab: Anzahl Einschaltungen und Betriebsstunden. Stimmt dieses Verhältnis, steht einem effizienten Betrieb nichts im Weg.</p>\n<p><strong>Wir empfehlen diese und weitere betriebsrelevante Parameter aktiv zu überwachen, nur so kann ein optimierter Betrieb mit dem gewünschten Komfort sichergestellt werden.</strong></p>\n<p>ab CHF 190.-- pro Jahr zzgl. MwSt.</p>\n",
    "image": {
        "contentType": "image/svg+xml",
        "url": "//images.ctfassets.net/wbm1kpreb3m8/6KSsjoAmclvwx6Ecth3flg/baf35bba04e861a6b4e254250ea8d310/Icon_Monitoring_copy-01.svg"
    },
    "position": true,
    "anchorName": "monitoring-empfohlen",
    "link": {
        "type": "link",
        "id": "3JLxdXdqi2DgXMzyNXttoT",
        "updatedAt": "2021-02-09T20:15:24.489Z",
        "label": "Aktives Monitoring bestellen",
        "url": "https://go.heizungsmacher.ch/monitoring"
    },
    "backgroundColor": "weiß"
}} location={props.location}
          />
        </div>
    </Layout>
  )
}

export default Page
